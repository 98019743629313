import React from "react"
import { useCourseStore } from "../../../../store/courses"
import LayoutCourse from "../../../../layouts/course"
import Seo from "../../../../components/seo"
import Stack from "../../../../components/stack"
import Box from "../../../../components/box"
import CourseFooterNext from "../../../../components/course-footer-next"
import BulletList from "../../../../components/bullet-list"
import Bullet from "../../../../components/bullet"
import Paragraph from "../../../../components/paragraph"
import { getCourseNavigation } from "../../../../store/courses"
import FeedbackHeaderProgress from "../../../../components/feedback-header-progress"

const Page = () => {
  const { getStatsForChapterWithBoolean } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "versteckte-ordnung" })
  const statistics = getStatsForChapterWithBoolean({
    courseId: "versteckte-ordnung",
    chapterId: "01-gedicht-erkennen",
  })

  return (
    <LayoutCourse
      footer={
        <CourseFooterNext
          label="Zum nächsten Kapitel"
          to="/kurse/versteckte-ordnung/02-ordnung"
        />
      }
      navigation={navigation}
    >
      <Seo title="Zusammenfassung" />
      <Box>
        <Stack>
          <FeedbackHeaderProgress
            correct={statistics.correct}
            total={statistics.correct + statistics.incorrect - 1}
          />
          <Paragraph>
            Woran erkennt man nun ein Gedicht? Eine kurze Zusammenfassung:
          </Paragraph>
          <BulletList size={[3, 3, 4, 5]}>
            <Bullet>
              Ein Gedicht hat eine feste Form. Es besteht aus Strophen und
              Versen.
            </Bullet>
            <Bullet>
              Die Sätze sind im Gedicht so angeordnet, dass sie sich der
              Gedichtform anpassen.
            </Bullet>
            <Bullet>
              In einem Gedicht gibt es oft Wiederholungen, durch die eine
              bestimmte Melodie entsteht.
            </Bullet>
            <Bullet>
              Das können Wortwiederholungen, aber auch Klangwiederholungen (zum
              Beispiel Reime) sein.
            </Bullet>
            <Bullet>
              Häufig geht es darum, eine bestimmte Stimmung zu erzeugen.
            </Bullet>
          </BulletList>
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
